<template>
  <section class="template-add-wrapper">
    <b-row class="template-add">
      <!-- Col: Left (Template Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="template-preview-card">
            <!-- Template Client & Payment Details -->
            <b-card-body class="template-padding">
              <h6 class="mb-1">Additional Information</h6>
              <b-row class="template-spacing">
                <!-- Col: Pathway Name -->
                <b-col cols="12" xl="4" md="12" class="mb-lg-1">
                  <label>Name</label>
                  <b-form-input
                    id="template-data-sales-person"
                    v-model="templateData.name"
                    placeholder="CompTIA Security+"
                  />
                </b-col>

                <!-- Col: Default? -->
                <b-col cols="12" xl="1" sm="6">
                  <label for="template-data-default">Default?</label>
                  <b-form-checkbox id="template-data-default" v-model="templateData.default" switch />
                </b-col>

                <!-- Col: Automatic? -->
                <b-col cols="12" xl="1" sm="6">
                  <label for="template-data-automatic">Automatic?</label>
                  <b-form-checkbox id="template-data-automatic" v-model="templateData.automatic" switch />
                </b-col>

                <!-- Col: Last Date of Attendance -->
                <b-col cols="12" xl="6" md="12" class="mb-lg-1">
                  <b-form-group label="Funding Source" label-for="profile-attr-funding-source">
                    <v-select
                      id="profile-attr-funding-source"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      :value="templateData.fundingSources"
                      :options="fundingSourceOptions"
                      class="w-100"
                      :clearable="false"
                      :reduce="(val) => val.value"
                      @input="(val) => (templateData.fundingSources = val)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols='12' sm='12' md='6' class="mb-lg-1">
                  <b-form-group label='Pathway' label-for='template-pathway'>
                    <v-select
                      id='template-pathway'
                      :value='templateData.pathway'
                      :options='pathwaySuggestions'
                      class='w-100'
                      :reduce='(val) => val.id'
                      @search='searchForPathway'
                      @input='(val) => updatePathway(val)'
                      :disable='!!templateData.subject'
                    />
                  </b-form-group>
                </b-col>
                <b-col cols='12' sm='12' md='6' class="mb-lg-1">
                  <b-form-group label='Subject' label-for='template-subject'>
                    <v-select
                      id='template-subject'
                      :value="templateData.subject"
                      :options="subjectSuggestions"
                      class="w-100"
                      :reduce="(val) => val.id"
                      @search="searchForSubject"
                      @input="(val) => updateSubject(val)"
                      :disabled="!!templateData.pathway"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" md="6" sm="12" class="mb-lg-1">
                  <label>Effective Start Date</label>
                  <b-form-datepicker
                    class="w-100"
                    id="program-date-start"
                    :value="templateData.effectiveBetween.startDate"
                    @input="(val) => (templateData.effectiveBetween.startDate = val)"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                  />
                </b-col>

                <b-col cols="12" lg="3" md="6" sm="12" class="mb-lg-1">
                  <label>Effective End Date</label>
                  <b-form-datepicker
                    class="w-100"
                    id="program-date-end"
                    :value="templateData.effectiveBetween.endDate"
                    @input="(val) => (templateData.effectiveBetween.endDate = val)"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    reset-button
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card>
            <b-card-body class="p-0">
              <b-card-title>
                Payments

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewPaymentInItemForm"
                  class="float-right"
                >
                  Add Payment
                </b-button>
              </b-card-title>

              <div ref="formPmnt" class="repeater-form" :style="{ height: trHeight['pmnt'] || 'auto' }">
                <b-row v-for="(item, index) in payments" :key="index" ref="rowPmnt" class="pb-2">
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <b-col cols="12" lg="6" md="12">
                          <b-form-group :label-for="`template-data-payment-${index}-description`" label="Description">
                            <b-form-input :id="`template-data-payment-${index}-description`" v-model="item.description" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3" md="6" sm="12">
                          <b-form-group :label-for="`template-data-payment-${index}-amount`" label="Amount">
                            <b-input-group prepend="$">
                              <cleave
                                :id="`template-data-payment-${index}-amount`"
                                v-model="item.amount"
                                class="form-control"
                                :raw="true"
                                :options="options.number"
                                placeholder="0"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3" md="6" sm="12">
                          <b-form-group :label-for="`template-data-payment-${index}-type`" label="Status">
                            <v-select
                              v-model="item.type"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="paymentTypeOptions"
                              placeholder="Payment Status"
                              :id="`template-data-payment-${index}-type`"
                              :reduce="(val) => val.value"
                              :clearable="false"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="3" md="6" sm="12">
                          <b-form-group :label-for="`template-data-payment-${index}-status`" label="Status">
                            <v-select
                              v-model="item.status"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="statusOptions"
                              placeholder="Payment Status"
                              :id="`template-data-payment-${index}-status`"
                              :reduce="(val) => val.value"
                              :clearable="false"
                            />
                          </b-form-group>
                        </b-col>

                        <b-row v-if="item.date" class="flex-grow-1 pt-1 px-1">
                          <b-col cols="12" lg="3" md="3" sm="12">
                            <b-form-group :label-for="`template-data-payment-${index}-date-relation`" label="Schedule">
                              <v-select
                                v-model="item.date.relation"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="dateScheduleRelative"
                                placeholder="After"
                                :id="`template-data-payment-${index}-date-relation`"
                                :reduce="(val) => val.value"
                                :clearable="false"
                              />
                            </b-form-group>
                          </b-col>
                          <template v-if='item.date.relation !== "absolute"'>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-payment-${index}-date-type`" label="From">
                                <v-select
                                  v-model="item.date.type"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="dateScheduleType"
                                  placeholder="Start Date"
                                  :id="`template-data-payment-${index}-date-type`"
                                  :reduce="(val) => val.value"
                                  :clearable="false"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-payment-${index}-date-count`" label="Count">
                                <b-form-input
                                  v-model="item.date.count"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  placeholder="1"
                                  :id="`template-data-payment-${index}-date-count`"
                                  type="number" step="1"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-payment-${index}-date-unit`" label="Unit">
                                <v-select
                                  v-model="item.date.unit"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="dateScheduleUnit"
                                  placeholder="Day"
                                  :id="`template-data-payment-${index}-date-unit`"
                                  :reduce="(val) => val.value"
                                  :clearable="false"
                                />
                              </b-form-group>
                            </b-col>
                          </template>
                          <template v-else-if='item.date.relation === "absolute"'>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-payment-${index}-date-absolute`" label="Date">
                             <flat-pickr
                                  :id="`template-data-payment-${index}-date-absolute`"
                                  v-model="item.date.date"
                                  class="form-control"
                                  placeholder=""
                                  :clearable="false"
                                  :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                          </template>
                        </b-row>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removePaymentItem(index)" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>

          <b-card>
            <b-card-body class="p-0">
              <b-card-title>
                Charges

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewChargeInItemForm"
                  class="float-right"
                >
                  Add Charge
                </b-button>
              </b-card-title>

              <div ref="formChrg" class="repeater-form" :style="{ height: trHeight['chrg'] || 'auto' }">
                <b-row v-for="(item, index) in charges" :key="index" ref="rowChrg" class="pb-2">
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="8" sm="12">
                          <b-form-group :label-for="`template-data-charge-${index}-description`" label="Description">
                            <b-form-input :id="`template-data-charge-${index}-description`" v-model="item.description" />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4" sm="12">
                          <b-form-group :label-for="`template-data-charge-${index}-amount`" label="Amount">
                            <b-input-group prepend="$">
                              <cleave
                                :id="`template-data-charge-${index}-amount`"
                                v-model="item.amount"
                                class="form-control"
                                :raw="true"
                                :options="options.number"
                                placeholder="0"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>

                        <b-row v-if="item.date" class="flex-grow-1 pt-1 px-1">
                          <b-col cols="12" lg="3" md="3" sm="12">
                            <b-form-group :label-for="`template-data-charge-${index}-date-relation`" label="Schedule">
                              <v-select
                                v-model="item.date.relation"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="dateScheduleRelative"
                                placeholder="After"
                                :id="`template-data-payment-${index}-date-relation`"
                                :reduce="(val) => val.value"
                                :clearable="false"
                              />
                            </b-form-group>
                          </b-col>
                          <template v-if='item.date.relation !== "absolute"'>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-charge-${index}-date-type`" label="From">
                                <v-select
                                  v-model="item.date.type"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="dateScheduleType"
                                  placeholder="Start Date"
                                  :id="`template-data-charge-${index}-date-type`"
                                  :reduce="(val) => val.value"
                                  :clearable="false"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-charge-${index}-date-count`" label="Count">
                                <b-form-input
                                  v-model="item.date.count"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  placeholder="1"
                                  :id="`template-data-charge-${index}-date-amount`"
                                  type="number" step="1"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-charge-${index}-date-unit`" label="Unit">
                                <v-select
                                  v-model="item.date.unit"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="dateScheduleUnit"
                                  placeholder="Day"
                                  :id="`template-data-charge-${index}-date-unit`"
                                  :reduce="(val) => val.value"
                                  :clearable="false"
                                />
                              </b-form-group>
                            </b-col>
                          </template>
                          <template v-else-if='item.date.relation === "absolute"'>
                            <b-col cols="12" lg="3" md="3" sm="12">
                              <b-form-group :label-for="`template-data-payment-${index}-date-absolute`" label="Date">
                                <flat-pickr
                                  :id="`template-data-payment-${index}-date-absolute`"
                                  v-model="item.date.date"
                                  class="form-control"
                                  placeholder=""
                                  :clearable="false"
                                  :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Z' }"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                          </template>
                        </b-row>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeChargeItem(index)" />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="template-actions mt-md-0 mt-2">
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="updateTemplate"
            :disabled="!isLoading"
          >
            Save Changes
          </b-button>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="$router.back()"
          >
            &leftarrow; Return
          </b-button>
        </b-card>

        <b-col>
          <statistic-card-horizontal
            fa-icon="fa-solid fa-dollar-sign"
            :statistic="money(totalDue)"
            statistic-title="Total Due"
            color="info"
          />
        </b-col>
        <b-col>
          <statistic-card-horizontal
            fa-icon="fa-solid fa-dollar-sign"
            :statistic="money(totalPaid)"
            statistic-title="Total Paid"
            color="success"
          />
        </b-col>
        <b-col>
          <statistic-card-horizontal
            fa-icon="fa-solid fa-dollar-sign"
            :statistic="money(totalBalance)"
            statistic-title="Remaining Balance"
            color="secondary"
          />
        </b-col>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue';
import { ref, onUnmounted, onMounted, computed } from '@vue/composition-api';
import { dynamicHeightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormDatepicker,
  BCardTitle,
  BCardHeader,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import router from '@/router';
import FormDatepicker from '@/views/forms/form-element/form-datepicker/FormDatepicker';
import FormDatepickerBasic from '@/views/forms/form-element/form-datepicker/FormDatepickerBasic';

import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import receivableStoreModule from '@/views/apps/accounting/receivable/receivableStoreModule';
import useTemplatesList from '@/views/apps/accounting/receivable/template-list/useTemplatesList';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import { useToast } from 'vue-toastification/composition';
import useAccountsList from '@/views/apps/accounting/receivable/account-list/useAccountsList';

export default {
  components: {
    Cleave,
    FormDatepickerBasic,
    FormDatepicker,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BFormSelect,
    BPopover,
    BCardTitle,
    BCardHeader,
    flatPickr,
    vSelect,
    Logo,
    StatisticCardHorizontal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [dynamicHeightTransition],
  destroyed() {
    window.removeEventListener('resize', this.initTrHeightPayment);
    window.removeEventListener('resize', this.initTrHeightCharge);
  },
  watch: {
    templateData(v) {
      this.initTrHeightPayment();
      this.initTrHeightCharge();
      this.searchForPathway(this.templateData.pathway)
      this.searchForSubject(this.templateData.subject)
    },
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },

    addNewPaymentInItemForm() {
      this.payments.push(JSON.parse(JSON.stringify(this.itemFormBlankPayment)));
      this.initTrHeightPayment();
    },
    removePaymentItem(index) {
      this.payments.splice(index, 1);
      this.initTrHeightPayment();
    },
    initTrHeightPayment() {
      this.trSetHeight('pmnt', null);
      this.$nextTick(() => {
        this.trSetHeight('pmnt', this.$refs.formPmnt.scrollHeight);
      });
    },
    addNewChargeInItemForm() {
      this.charges.push(JSON.parse(JSON.stringify(this.itemFormBlankCharge)));
      this.initTrHeightCharge();
    },
    removeChargeItem(index) {
      this.charges.splice(index, 1);
      this.initTrHeightCharge();
    },
    initTrHeightCharge() {
      this.trSetHeight('chrg', null);
      this.$nextTick(() => {
        this.trSetHeight('chrg', this.$refs.formChrg.scrollHeight);
      });
    },

    initiateTemplateEdit() {
      console.log(this.templateData);

      // Remove fields that could cause issues and are automatically calculated
      delete this.templateData.balance;

      // Remove fields that could cause issues and are automatically calculated
      delete this.templateData.balance;

      this.$http
        .patch(`templates/${this.templateId}`, this.templateData)
        .then((response) => {
          if (response.status === 200) {
            const { id } = response.data;

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Template Updated`,
                icon: 'ArchiveIcon',
                variant: 'success',
                text: `Your template has successfully been modified!`,
              },
            });

            this.$router.push({ name: 'apps-template-preview', params: { id } });
          }

          console.log(response);
        })
        .catch((e) => {
          console.error(e);
        });

      console.log(this.templateData);
    },
  },
  data: function () {
    return {
      templateId: this.$router.currentRoute.params.id,

      // templateData: {
      //   charges: {},
      //   paymentPlan: {},
      //   payments: [],
      //   status: 'graduated',
      // },
      paymentDetails: {},
      studentStatusOptions: [
        { value: 'graduated', text: 'Graduated' },
        { value: 'terminated', text: 'Terminated' },
        { value: 'withdrew', text: 'Withdrew' },
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    };
  },
  setup() {
    const TEMPLATE_APP_STORE_MODULE_NAME = 'app-accounting-receivable';
    const ROSTER_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME))
      store.registerModule(TEMPLATE_APP_STORE_MODULE_NAME, receivableStoreModule);
    // Register module
    if (!store.hasModule(ROSTER_APP_STORE_MODULE_NAME))
      store.registerModule(ROSTER_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_APP_STORE_MODULE_NAME);
      if (store.hasModule(ROSTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ROSTER_APP_STORE_MODULE_NAME);
    });

    const { fundingSourceOptions, paymentStatusOptions: statusOptions, paymentTypeOptions } = useAccountsList();
    const { dateScheduleRelative, dateScheduleType, dateScheduleUnit } = useTemplatesList();

    const toast = useToast();

    const itemFormBlankCharge = Object.freeze({
      date: { relation: 'after', type: 'start_date', count: 0, unit: 'day' },
      description: 'Manual Charge',
      amount: 0,
    });

    const itemFormBlankPayment = Object.freeze({
      date: { relation: 'after', type: 'start_date', count: 0, unit: 'day' },
      description: 'Manual Payment',
      amount: 0,
      status: 'unpaid',
    });

    const isLoading = ref(false);
    const templateData = ref({ charges: [], payments: [], program: {}, effectiveBetween: { startDate: undefined, endDate: undefined } });
    const studentData = ref({});
    const profileData = ref({});
    const cohortData = ref({});

    const _mds = (charge) => {
      const date = charge.date;

      let d = { relation: '', type: '', count: 0, unit: '' };
      if (date && date.before) {
        d.relation = 'before';
        if (date.before.type) {
          d.type = date.before.type;
        }
        if (date.before.unit) {
          d.unit = date.before.unit;
        }
        if (date.before.count) {
          d.count = date.before.count;
        }
      } else if (date && date.after) {
        d.relation = 'after';
        if (date.after.type) {
          d.type = date.after.type;
        }
        if (date.after.unit) {
          d.unit = date.after.unit;
        }
        if (date.after.count) {
          d.count = date.after.count;
        }
      } else if (date && date.absolute) {
        d.relation = 'absolute';
      }

      charge.date = d;
      return charge;
    };

    const charges = ref([]);

    const payments = ref([]);

    const fetchTemplate = async () => {
      isLoading.value = true;
      store
        .dispatch('app-accounting-receivable/fetchTemplate', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'student profile cohort' },
        })
        .then((response) => {
          const { data } = response;

          data.charges = data.charges.map(_mds)
          data.payments = data.payments.map(_mds)

          templateData.value = data;
          studentData.value = data.student;
          profileData.value = data.profile;
          cohortData.value = data.cohort;

          charges.value = data.charges;
          payments.value = data.payments;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              templateData.value = {};
            }

            console.error(`Failed to fetch template`, error);
            return;
          }

          console.error(error);
        });
    };

    const updateTemplate = async () => {
      isLoading.value = true;

      console.log(`updating template`, templateData.value);

      const d = (payment) => {
        const ol = {};
        const d = payment.date;
        console.log(`d (payment) >`, d);
        ol[d.relation] = {
          count: d.count,
          unit: d.unit,
          type: d.type,
        };
        delete payment.date;
        return { ...payment, date: ol };
      };

      const ad = templateData.value;

      ad.charges = charges.value;
      ad.payments = payments.value;

      const payload = {
        charges: ad.charges.map(d),
        payments: ad.payments.map(d),
        program: ad.program,
        // comments: ad.comments,
        name: ad.name,
        pathway: ad.pathway,
        subject: ad.subject,
        default: ad.default,
        automatic: ad.automatic,
        fundingSources: ad.fundingSources,
        effectiveBetween: ad.effectiveBetween
      };

      if (payload.subject && payload.pathway) {
        payload.subject = null
      }

      console.log(`template payload`, payload);

      store
        .dispatch('app-accounting-receivable/updateTemplate', {
          id: router.currentRoute.params.id,
          templateData: payload,
        })
        .then((response) => {
          const { data } = response;
          // templateData.value = data;
          // studentData.value = data.student;
          // profileData.value = data.profile;
          // cohortData.value = data.cohort;

          fetchTemplate()

          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              variant: 'success',
              text: `Successfully saved changes to template`,
            },
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 404) {
              templateData.value = { found: false };
              return;
            }
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                variant: 'danger',
                title: `Failed to save changes`,
                text: error.response.data.message,
              },
            });

            console.error(`Failed to fetch template`, error);
            return;
          }

          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              variant: 'danger',
              title: `Failed to save changes`,
              text: 'An unknown error occurred',
            },
          });
        });
    };

    onMounted(() => {
      fetchTemplate();
    });

    const totalDue = computed(() => {
      if (!templateData.value.charges) return 0;
      return templateData.value.charges.reduce((total, charge) => Number(charge.amount) + total, 0);
    });
    const totalPaid = computed(() => {
      if (!templateData.value.payments) return 0;
      return templateData.value.payments
        .filter((payment) => payment.status === 'paid')
        .reduce((total, payment) => Number(payment.amount) + total, 0);
    });
    const totalBalance = computed(() => {
      return totalDue.value - totalPaid.value;
    });

    /// pathway search
    const pathwayQuery = ref('');
    const pathwaySuggestions = ref([]);

    const searchForPathway = async (search, loading) => {
      const response = await store.dispatch('app-roster/fetchPathways', { search });
      const pathways = response.data.results;

      pathwaySuggestions.value = pathways.map((p) => {
        let { id, name } = p;
        if (!name.includes('Pathway')) {
          name += ' Pathway';
        }
        return { id, label: name };
      });
    }

    const updatePathway = async (val) => {
      console.log(`update pathway`, val);
      templateData.value.pathway = val;
    };
    /// subject search
    const subjectQuery = ref('');
    const subjectSuggestions = ref([]);

    const searchForSubject = async (search, loading) => {
      const response = await store.dispatch("app-roster/fetchSubjects", { search });
      const subjects = response.data.results;

      subjectSuggestions.value = subjects.map((p) => {
        let { id, name } = p;
        return { id, label: name };
      });
    };

    const updateSubject = async (val) => {
      console.log(`update subject`, val);
      templateData.value.subject = val;
    };

    return {
      // templateData,
      clients: [],
      itemFormBlankCharge,
      itemFormBlankPayment,

      isLoading,
      fetchTemplate,
      updateTemplate,

      statusOptions,
      fundingSourceOptions,
      paymentTypeOptions,

      dateScheduleRelative,
      dateScheduleType,
      dateScheduleUnit,

      templateData,
      studentData,
      profileData,
      cohortData,

      totalDue,
      totalPaid,
      totalBalance,

      payments,
      charges,

      //
      pathwayQuery,
      pathwaySuggestions,
      updatePathway,
      searchForPathway,
      //
      subjectQuery,
      subjectSuggestions,
      updateSubject,
      searchForSubject
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
//@import '@core/scss/vue/libs/vue-flatpicker.scss'; // disabled due to it making the calendars look like shit

.template-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-report.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
